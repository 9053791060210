<template>
  <v-container>
    <v-row>
      <v-col class="pb-0">
        <v-card-title
          class="pa-0"
          style="display: flex; flex-wrap: nowrap; align-items: baseline;"
        >
          <v-img
            src="@/assets/img/title-item.png"
            max-width="10px"
            max-height="20px"
          ></v-img>
          <span class="font-weight-bold ml-2" style="font-size: large;">
            {{ title }}
          </span>
        </v-card-title>
        <span if="explanation">
          {{ explanation }}
        </span>
      </v-col>
      <v-container class="pt-2">
        <table class="confirm-table">
          <tbody>
            <tr>
              <td class="header-color">
                <p>{{ $t('header.reviewContractTable.stepFourthContent.confirmMethod') }}</p>
              </td>
              <td class="header-color">
                <p>{{ $t('header.reviewContractTable.stepFourthContent.securities') }}</p>
              </td>
              <td class="header-color">
                <p>{{ $t('header.reviewContractTable.stepFourthContent.agreement') }}</p>
              </td>
              <td class="header-color">
                <p>{{ $t('header.reviewContractTable.stepFourthContent.contactNextMaturity') }}</p>
              </td>
            </tr>
            <tr>
              <td class="header-color">
                <p>{{ $t('header.reviewContractTable.stepFourthContent.web') }}</p>
              </td>
              <td class="check-box">
                <p>{{ record.webSecurity }}</p>
              </td>
              <td class="check-box">
                <p>{{ record.webAgreement }}</p>
              </td>
              <td class="check-box">
                <p>{{ record.webExpireGuidance }}</p>
              </td>
            </tr>
            <tr>
              <td class="header-color" rowspan="2">
                <p>{{ $t('header.reviewContractTable.stepFourthContent.document') }}</p>
              </td>
              <td class="check-box">
                <p>{{ record.documentSecurity }}</p>
              </td>
              <td class="check-box">
                <p>{{ record.documentAgreement }}</p>
              </td>
              <td class="check-box">
                <p>{{ record.documentExpireGuidance }}</p>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <template v-if="record.contractUserAddress && record.addressDesignat">
                  <p><span class="check-box">{{ record.contractUserAddress }}</span> {{ $t('header.reviewContractTable.stepFourthContent.contractUserAddress') }}</p>
                  <p><span class="check-box">{{ record.addressDesignat }}</span> {{ $t('header.reviewContractTable.stepFourthContent.addressDesignat') }}</p>
                </template>
                <template v-else>
                  <p class="no-text">{{ $t('header.reviewContractTable.stepFourthContent.noText') }}</P>
                </template>
              </td>
              <td class="cell-center">
                 <p class="no-text">{{ $t('header.reviewContractTable.stepFourthContent.noText') }}</p>
              </td>
            </tr>
            <tr>
              <td colspan="4" class="x-5" style="text-align: left; white-space: normal;">
                <template v-if="record.confirmationText === $t('header.reviewContractTable.stepFourthContent.noText')">
                  <p class="ma-0 no-text">{{ $t('header.reviewContractTable.stepFourthContent.noText') }}</P>
                </template>
                <template v-else>
                  <p
                    v-for="(message, index) in splitMessageLine(record.confirmationText)"
                    :key="index"
                    class="ma-0"
                    style="word-wrap: break-word;"
                  >
                  {{ message }}
                  </p>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </v-container>
    </v-row>
    <ItemExplanationDialog
      :maxWidth="320"
      :showDialog="showItemExplanation"
      :title="explanationItemTitle"
      :text="explanationItemText"
      :negativeButtonTitle="$t('button.close')"
      :onClickNegativeButton="toggleShowItemExplanation"
    />
  </v-container>
</template>
<script>
import { splitMessageLine } from '@/lib/commonUtil';
import ItemExplanationDialog from '@/components/organisms/contractor/ItemExplanationDialog.vue';
export default {
  components: {
    ItemExplanationDialog,
  },
  props: {
    title: String,
    explanation: String,
    contentPageName: String,
    record: Object,
  },
  data() {
    return {
      showItemExplanation: false,
      explanationItemText: '',
      explanationItemTitle: '',
      activePlanTab: 'planTab1',
    };
  },
  methods: {
    splitMessageLine(message) {
      return splitMessageLine(message);
    },
    isExplanationItem(key) {
      return this.$te(this.targetI18n(key));
    },
    targetI18n(key) {
      return `tableItems.reviewContract.${this.contentPageName}.explanationItems.${key}`;
    },
    toggleShowItemExplanation(key) {
      this.showItemExplanation = !this.showItemExplanation;
      if (this.showItemExplanation) {
        this.explanationItemTitle = this.$t(`header.planTable.${key}`);
        this.explanationItemText = this.$t(this.targetI18n(key));
      } else {
        this.explanationItemTitle = '';
        this.explanationItemText = '';
      }
    },
  },
};
</script>
<style>
.confirm-table {
  border-collapse: collapse;
  width: 100%;
  border-spacing: 5px;
  font-size: 14px;
  table-layout: fixed;
}
.confirm-table th.active {
  background-color: #a9cbf4;
}
.confirm-table th,
.confirm-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
  vertical-align: top;
  white-space: pre-wrap;
}
.confirm-tab {
  cursor: pointer;
}
.no-text {
  font-size: 1.5em;
}
.header-color {
  background: #d5ebfe;
}
.check-box p,
span.check-box {
  font-family: Arial, sans-serif;
  text-align: center;
  font-size: 1.5em;
}
.item-explanation {
  text-decoration: underline;
  cursor: pointer;
  color: #0069d8;
}
.v-data-table td {
  word-break: break-all;
}
.v-data-table {
  overflow: hidden;
}
.cell-center {
  text-align: center !important;
  vertical-align: middle !important;
}
</style>