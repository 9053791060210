var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-0" },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "pa-0",
                  staticStyle: {
                    display: "flex",
                    "flex-wrap": "nowrap",
                    "align-items": "baseline",
                  },
                },
                [
                  _c("v-img", {
                    attrs: {
                      src: require("@/assets/img/title-item.png"),
                      "max-width": "10px",
                      "max-height": "20px",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-bold ml-2",
                      staticStyle: { "font-size": "large" },
                    },
                    [_vm._v("\n          " + _vm._s(_vm.title) + "\n        ")]
                  ),
                ],
                1
              ),
              _c("span", { attrs: { if: "explanation" } }, [
                _vm._v("\n        " + _vm._s(_vm.explanation) + "\n      "),
              ]),
            ],
            1
          ),
          _c("v-container", { staticClass: "pt-2" }, [
            _c("table", { staticClass: "confirm-table" }, [
              _c("tbody", [
                _c("tr", [
                  _c("td", { staticClass: "header-color" }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "header.reviewContractTable.stepFourthContent.confirmMethod"
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c("td", { staticClass: "header-color" }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "header.reviewContractTable.stepFourthContent.securities"
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c("td", { staticClass: "header-color" }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "header.reviewContractTable.stepFourthContent.agreement"
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c("td", { staticClass: "header-color" }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "header.reviewContractTable.stepFourthContent.contactNextMaturity"
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", { staticClass: "header-color" }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "header.reviewContractTable.stepFourthContent.web"
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c("td", { staticClass: "check-box" }, [
                    _c("p", [_vm._v(_vm._s(_vm.record.webSecurity))]),
                  ]),
                  _c("td", { staticClass: "check-box" }, [
                    _c("p", [_vm._v(_vm._s(_vm.record.webAgreement))]),
                  ]),
                  _c("td", { staticClass: "check-box" }, [
                    _c("p", [_vm._v(_vm._s(_vm.record.webExpireGuidance))]),
                  ]),
                ]),
                _c("tr", [
                  _c(
                    "td",
                    { staticClass: "header-color", attrs: { rowspan: "2" } },
                    [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "header.reviewContractTable.stepFourthContent.document"
                            )
                          )
                        ),
                      ]),
                    ]
                  ),
                  _c("td", { staticClass: "check-box" }, [
                    _c("p", [_vm._v(_vm._s(_vm.record.documentSecurity))]),
                  ]),
                  _c("td", { staticClass: "check-box" }, [
                    _c("p", [_vm._v(_vm._s(_vm.record.documentAgreement))]),
                  ]),
                  _c("td", { staticClass: "check-box" }, [
                    _c("p", [
                      _vm._v(_vm._s(_vm.record.documentExpireGuidance)),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c(
                    "td",
                    { attrs: { colspan: "2" } },
                    [
                      _vm.record.contractUserAddress &&
                      _vm.record.addressDesignat
                        ? [
                            _c("p", [
                              _c("span", { staticClass: "check-box" }, [
                                _vm._v(_vm._s(_vm.record.contractUserAddress)),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "header.reviewContractTable.stepFourthContent.contractUserAddress"
                                    )
                                  )
                              ),
                            ]),
                            _c("p", [
                              _c("span", { staticClass: "check-box" }, [
                                _vm._v(_vm._s(_vm.record.addressDesignat)),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "header.reviewContractTable.stepFourthContent.addressDesignat"
                                    )
                                  )
                              ),
                            ]),
                          ]
                        : [
                            _c("p", { staticClass: "no-text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "header.reviewContractTable.stepFourthContent.noText"
                                  )
                                )
                              ),
                            ]),
                          ],
                    ],
                    2
                  ),
                  _c("td", { staticClass: "cell-center" }, [
                    _c("p", { staticClass: "no-text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "header.reviewContractTable.stepFourthContent.noText"
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c(
                    "td",
                    {
                      staticClass: "x-5",
                      staticStyle: {
                        "text-align": "left",
                        "white-space": "normal",
                      },
                      attrs: { colspan: "4" },
                    },
                    [
                      _vm.record.confirmationText ===
                      _vm.$t(
                        "header.reviewContractTable.stepFourthContent.noText"
                      )
                        ? [
                            _c("p", { staticClass: "ma-0 no-text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "header.reviewContractTable.stepFourthContent.noText"
                                  )
                                )
                              ),
                            ]),
                          ]
                        : _vm._l(
                            _vm.splitMessageLine(_vm.record.confirmationText),
                            function (message, index) {
                              return _c(
                                "p",
                                {
                                  key: index,
                                  staticClass: "ma-0",
                                  staticStyle: { "word-wrap": "break-word" },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(message) +
                                      "\n                "
                                  ),
                                ]
                              )
                            }
                          ),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c("ItemExplanationDialog", {
        attrs: {
          maxWidth: 320,
          showDialog: _vm.showItemExplanation,
          title: _vm.explanationItemTitle,
          text: _vm.explanationItemText,
          negativeButtonTitle: _vm.$t("button.close"),
          onClickNegativeButton: _vm.toggleShowItemExplanation,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }